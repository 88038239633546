import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { ComponentModule } from './shared/component/component.module';
import { AddUserAgentInterceptor } from './shared/service/http/http.interceptor';
import { MainModule } from './main/main.module';
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AddUserAgentInterceptor, multi: true }
];


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ComponentModule,
    MainModule,
    HammerModule
  ],
  providers: [
    provideClientHydration(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
