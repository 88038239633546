import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ApiError, ErrorCodes } from './shared/models/http.model';
import { CommonService } from './shared/service/common/common.service';
import { HttpService } from './shared/service/http/http.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  isMaintenance: boolean = false;

  constructor(
    private commonService: CommonService,
    private httpService: HttpService,
    private location: Location
  ) {
    this.commonService.setLanguageFromUrl(this.location.path()).subscribe(language => {
      this.httpService.setLanguage(language);
    });

    this.commonService.initializeDomains().subscribe({
      next: (domains) => {
        this.httpService.setDomains(domains);
      },
      error: (errors: ApiError[]) => {
        if (errors.some(error => error.code == ErrorCodes.Maintenance)) {
          this.isMaintenance = true;
        }
      }
    });

    this.commonService.maintenanceToggle$.subscribe(isMaintenance => this.isMaintenance = isMaintenance);
  }
}