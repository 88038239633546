@if (!isMaintenance) {
    <main>
        <router-outlet></router-outlet>
    </main>
} @else {
    <app-maintenance></app-maintenance>
}
<app-back-button></app-back-button>
<app-modal></app-modal>
<app-preloader></app-preloader>
<app-puzzle-captcha></app-puzzle-captcha>