import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, catchError, first, tap } from 'rxjs';
import UAParser from 'ua-parser-js';
import { APP_USER_AGENT, BaseResponse, ErrorCodes } from '../../models/http.model';
import { AuthService } from '../auth/auth.service';
import { CommonService } from '../common/common.service';
import { PlatformService } from '../platform/platform.service';
import { HttpService } from './http.service';

@Injectable()
export class AddUserAgentInterceptor implements HttpInterceptor {

  constructor(
    @Optional() @Inject(APP_USER_AGENT) private userAgent: string,
    private authService: AuthService,
    private httpService: HttpService,
    private commonService: CommonService,
    private platformService: PlatformService
  ) {
    this.commonService.domains$.pipe(first()).subscribe(domains => {
      this.higgsServer = domains.higgsServer;
    });
  }

  higgsServer: string = '';

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if(this.higgsServer && req.url.includes(this.higgsServer)) {
      const higgsJwt = this.authService.getHiggsJwt();
      let newRequest = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${higgsJwt}`)
      });

      if(req.url.split(this.higgsServer)[1] === '/scan') {
        const accessToken = this.authService.getScanAccessToken();
        newRequest = newRequest.clone({
          headers: newRequest.headers.set('Authorization', `Bearer ${accessToken}`)
        });
      }

      return next.handle(newRequest);
    }

    let userAgent: string = '';
    if (this.platformService.isBrowser()) {
      userAgent = this.convertToXUserAgent(window.navigator.userAgent);
    }

    if (this.platformService.isServer()) {
      userAgent = this.convertToXUserAgent(userAgent);
    }

    let newRequest = req.clone({
      headers: req.headers.set('X-User-Agent', userAgent)
    });

    newRequest = newRequest.clone({
      headers: newRequest.headers.set('Content-Type', 'application/json')
    });

    newRequest = newRequest.clone({
      headers: newRequest.headers.set('Accept-Language', this.httpService.getLanguage())
    });

    const accessToken = this.authService.getAccessToken();

    if( accessToken ) {
      newRequest = newRequest.clone({
        headers: newRequest.headers.set('Authorization', 'Bearer ' + accessToken)
      });
    }

    return next.handle(newRequest)
      .pipe(
        tap(event => {
          if (event.type === HttpEventType.Response) {
            this.interceptResponse(event.body);
          }
        }),
        catchError(err => {
          this.interceptResponse(err.error);
          throw err;
        })
      );
  }

  interceptResponse(response: BaseResponse<any>) {
    if((response?.errors ?? []).find(apiError => apiError.code == ErrorCodes.Maintenance)) {
      this.commonService.hideLoading();
      this.commonService.enableMaintenance();
    }
    return response;
  }

  convertToXUserAgent(originalUserAgent: string): string {
    const parser = new UAParser(originalUserAgent);
    const result = parser.getResult();

    // eslint-disable-next-line max-len
    return `Zeta ${result.browser.name}/${result.browser.version} (WAP ${result.os.name}/${result.os.version}; ${result.device.vendor} ${result.device.model}/**)`;
  }
}
